import React from "react"
import styled from "styled-components"
import RequestForm from "../components/RequestForm"
import GetTouchForm from "../components/GetTouchForm"
import { graphql } from "gatsby"
import Slider from "react-slick"
import { Container, Row, Col, Button, Modal, Breadcrumb } from "react-bootstrap"
import { Section, MarkdownContent } from "../components/Section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import StickyBox from "react-sticky-box"
import PatternImg from "../images/line-pattern.png"
import MoreInformation from "../components/MoreInformation"
import "@material/react-select/dist/select.css"
import {
  ArrowLeftIcon,
  ArrowrRightIcon,
  WidthIcon,
  HeightIcon,
  LenghtIcon,
} from "../components/Icons"
import WhatNeedKnow from "../components/WhatNeedKnow"

import Image from "../components/image"

const BtnWrapper = styled.div`
  padding: 0;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  .btn {
    margin: 0px 10px 10px;
    padding: 6px 25px;
    tetx-align: center;
    position: absolute;
    z-index: 1;

    &.arrow-next,
    &.arrow-prev {
      top: 50%;
      transform: translateY(-50%);
      background: #22213345;
      border-color: #fff;
      padding-top: 15px;
      padding-bottom: 15px;
      &:hover {
        background-color: #cb0000;
        border-color: #cb0000;
      }
      @media (max-width: 1400px) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      @media (max-width: 767px) {
        top: 100px;
      }
    }
    &.arrow-next {
      right: 0;
    }
    .arrow-next {
      left: 0;
    }
  }
`

const ProductCard = styled.div`
  position: relative;

  @media(max-width: 567px) {
    height: 270px;
    .product-figure {
      height: 100%;
    }
    .product-title {
      text-align: center;
    }
    .product-price {
      text-align: center;
    }
    .product-measurement {
      flex-wrap: wrap;

      .dimension {
        margin: auto;
      }

      .dimension .item {
        display: block;
        text-align: center;
      }
      .dimension .size {
        font-size: 20px;
      }

      .btn-toolbar {
        margin: 15px auto 0;
      }
    }
  }
`
function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class ProductDetail extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.state = {
      show: false,
      value: "pomsky",
    }
  }
  next() {
    this.Slider.slickNext()
  }
  previous() {
    this.Slider.slickPrev()
  }
  handleClose() {
    this.setState({
      show: false,
    })
  }
  handleShow() {
    this.setState({
      show: true,
    })
  }
  render() {
    const { show } = this.state
    var settings = {
      dots: false,
      arrows: false,
      loop: true,
      infinite: true,
      speed: 500,
      centerMode: true,      
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: "300px",
      NextArrow: <SampleNextArrow />,
      PrevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            centerPadding: "250px", 
          }
        },
        {
          breakpoint: 992,
          settings: {
            centerPadding: "60px"
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: "30px"
          }
        },
        {
          breakpoint: 567,
          settings: {
            centerPadding: "15px"
          }
        },
      ],
    }

    const pageData = this.props.data.markdownRemark
    const { location } = this.props;
    const formId = "Request a Quote";
    const formId1 = "Request a Call";
    return (
      <Layout location = { location }>
        <Modal
          show={show}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Request a Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GetTouchForm location = { location } formId = { formId } />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <SEO title="Prodcut Category" />
        <section style={{ background: `url(${PatternImg})` }}>
          <Container className="position-relative">
            <Breadcrumb className="beadcrumbs-outer">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item
                href={`/${pageData.frontmatter.mainCateogryURL}`}
              >
                {pageData.frontmatter.mainCateogry}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {pageData.frontmatter.subCategory}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </section>
        <Section
          className="section carport-solution"
          pt="100px"
          pb="100px"
          data-aos="fade-up"
        >
          <Container>
            <Row className="align-items-center">
              <Col lg={6} className="order-1 order-lg-0">
                <div className="section-heading">
                  {pageData.frontmatter.shortHeading && (
                    <span className="section-subtitle">
                      The Perfect Carport Solution!
                    </span>
                  )}
                  <h2 className="section-title">
                    {pageData.frontmatter.subCategory}
                  </h2>
                </div>
                <div className="section-content">
                  <h5 className="text-secondary mb-lg-5 mt-lg-5">
                    {pageData.frontmatter.mainSubHeading}
                  </h5>
                  <p>{pageData.frontmatter.mainDesc}</p>
                  <button
                    onClick={this.handleShow}
                    className="btn btn-secondary mt-4"
                  >
                    Request a Quote
                  </button>
                </div>
              </Col>
              <Col lg={{ span: 5, offset: 1 }}>
                <div className="img-after-shape">
                  <figure>
                    <Image
                      name={pageData.frontmatter.mainImage}
                      alt={pageData.frontmatter.mainImage}
                    />
                  </figure>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="section prdoct-slider">
          <div className="shew-shape-outer">
            <div className="skew-shape">
              <h3>{`${pageData.frontmatter.subCategory} All Projects`}</h3>
            </div>
            <div className="bottom-line d-none b-lg-block"></div>
          </div>

          <div className="section-content slick-product">
            <Slider {...settings} ref={c => (this.Slider = c)}>
              {pageData.frontmatter.productDetails.map(product => {
                return (
                  <div className="slick-item">
                    <ProductCard className="product-card">
                      <div className="product-figure">
                        <Image name={product.imageName} alt={product.imageName} />
                      </div>
                      <div className="product-body">
                        <div className="product-inner row">
                          <div className="col-xl-5">
                            <div className="product-title">{product.name}</div>
                            <div className="product-price">{product.price && <p>Starting Price: <strong>{product.price}</strong></p>}</div>
                          </div>
                          <div className="col-xl-7">
                            <div className="product-measurement">
                              <div className="dimension">
                                <div className="item">
                                  <span className="icon">
                                    <WidthIcon stroke="#fff" />
                                  </span>
                                  <span className="text">
                                    <span className="size">{product.width}</span>                    
                                    <span className="size-label">Width</span>
                                  </span>
                                </div>
                                <div className="item">
                                  <span className="icon">
                                    <LenghtIcon stroke="#fff" />
                                  </span>
                                  <span className="text">
                                    <span className="size">{product.length}</span>                    
                                    <span className="size-label">Length</span>
                                  </span>
                                </div>
                                <div className="item">
                                  <span className="icon">
                                    <HeightIcon stroke="#fff" />
                                  </span>
                                  <span className="text">
                                    <span className="size">{product.height}</span>                    
                                    <span className="size-label">Height</span>
                                  </span>
                                </div>
                              </div>
                              <div className="btn-toolbar">
                                <button onClick={this.handleShow} className="btn btn-secondary">Request a Quote</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ProductCard>
                  </div>
                )
              })}
            </Slider>
            <BtnWrapper>
              <Button variant="secondary arrow-prev" onClick={this.previous}>
                <ArrowLeftIcon stroke="#fff" width="22px" height="14px" />
              </Button>
              <Button variant="secondary arrow-next" onClick={this.next}>
                <ArrowrRightIcon
                  stroke="#fff"
                  Arro
                  width="22px"
                  height="14px"
                />
              </Button>
            </BtnWrapper>
          </div>
        </Section>
        <MoreInformation />
        {pageData.frontmatter.roofStyleHeading && 
          <WhatNeedKnow
            roofStyleSubHeading={pageData.frontmatter.roofStyleSubHeading}
            roofStyleHeading={pageData.frontmatter.roofStyleHeading}
            roofStyleDesc={pageData.frontmatter.roofStyleDesc}
            data={pageData.frontmatter.roofStyleData}
          />       
        }
        <Section
          className="section"
          pb="50px"
          pt="50px"
          bg={`url(${PatternImg})`}
        >
          <Container>
            <Row>
              <Col lg={7}>
                <MarkdownContent
                  dangerouslySetInnerHTML={{ __html: pageData.html }}
                />
              </Col>
              <Col xl={{ span: 4, offset: 1 }} lg={5}>
                <StickyBox offsetTop={40}>
                  <div className="form-wrapper">
                    <h2 className='form-title'>Request a Call</h2>                    
                    <RequestForm location = { location } formId = { formId1 }  />
                  </div>
                </StickyBox>
              </Col>
            </Row>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default ProductDetail

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        metaTitle
        metaDesc
        mainCateogry
        mainCateogryURL
        subCategory
        shortHeading
        mainHeading
        mainSubHeading
        mainDesc
        mainImage
        productDetails {
          name
          price
          width
          length
          height
          imageName
        }
        roofStyleSubHeading
        roofStyleHeading
        roofStyleDesc
        roofStyleData {
          roofDesc
          roofHeading
          roofSubHeading
        }
      }
      html
    }
  }
`
